<template>
    <div>
        <section class="login">
            <div class="half_inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_l text_black text_500">Mi cuenta</h2>
                    <a href="/productos" class="btn med graphic_right local_color">Salir<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></a>
                </header>
                <div class="account_data grid grid_pad flex_space">
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box content_block padded m_bottom rounded grey">
                            <h2 class="text_m mb30">Pedidos.</h2>
                            <div class="data_list">
                                <div class="data_list_item" v-for="(order, index) in user.orders" :key="index">
                                    <div class="data_list_item_data">
                                        <h3 class="text_s text_700 mb5">Pedido nº {{order.ref}}</h3>
                                        <p class="text_xs">16 de marzo de 2019</p>
                                        <p class="text_xs">1 artículo</p>
                                        <p class="text_x text_700">{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(getTotalOrder(order))}}</p>
                                    </div>
                                    <div class="data_list_item_edit">
                                        <div class="btn small graphic_simple grey" @click="verPedido(order.ref)" title="Ver Resumen">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.199.02-.393.057-.581 1.474.541 2.927-.882 2.405-2.371.174-.03.354-.048.538-.048 1.657 0 3 1.344 3 3zm-2.985-7c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 12c-2.761 0-5-2.238-5-5 0-2.761 2.239-5 5-5 2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"/></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="message info" v-if="user.orders.length <= 0">
                                <h3>Aún no has hecho ningún pedido</h3>
                            </div>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box content_block padded m_bottom rounded grey">
                            <h2 class="text_m mb30">Cambiar contraseña.</h2>
                            <form class="form" @submit.prevent="changePassword">
                                <div class="input_block">
                                    <input type="password" placeholder="Antigua contraseña" v-model="password_old"/>
                                </div>
                                <div class="input_block">
                                    <input type="password" placeholder="Nueva Contraseña" v-model="password_new"/>
                                </div>
                                <div class="input_block">
                                    <input type="password" placeholder="Confirmar nueva contraseña" v-model="password_confirmed"/>
                                </div>
                                <div class="input_block">
                                    <button type="submit">Actualizar</button>
                                </div>
                                <div class="input_block">
                                    <div class="legal_line">
                                        <p class="legal">Todos los campos son imprescindibles</p>
                                    </div>
                                </div>
                                <div class="input_block" v-if="message_password">
                                    <div class="message" :class="message_password_type ? 'ok' : 'ko'">
                                        <h3>{{ message_password }}</h3>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_2">
                        <div class="grid_col_box content_block padded m_bottom rounded grey">
                            <h2 class="text_m mb30">Direcciones de envío.</h2>
                            <div class="data_list">
                                <div class="data_list_item" v-for="(diretion, index) in user.addresses" :key="index">
                                    <div class="data_list_item_data">
                                        <h3 class="text_s text_700 mb10">{{diretion.title}} <!--<span class="data_default">Predeterminada</span>--></h3>
                                        <p class="text_xs mb5">{{diretion.name}} {{diretion.lastname}}</p>
                                        <p class="text_xs mb5">{{diretion.phone}}</p>
                                        <p class="text_xs mb5">{{diretion.address}} {{diretion.cp}} {{diretion.city}} ({{diretion.province}}).</p>
                                        <p class="text_xs mb5" v-if="diretion.address2">{{diretion.address2}}</p>
                                    </div>
                                    <div class="data_list_item_edit">
                                        <div class="btn small graphic_simple grey" title="Editar dirección" @click="editar(index)">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.078 4.232l-12.64 12.639-1.438 7.129 7.127-1.438 12.641-12.64-5.69-5.69zm-10.369 14.893l-.85-.85 11.141-11.125.849.849-11.14 11.126zm2.008 2.008l-.85-.85 11.141-11.125.85.85-11.141 11.125zm18.283-15.444l-2.816 2.818-5.691-5.691 2.816-2.816 5.691 5.689z"/></svg>
                                            </span>
                                        </div>
                                        <div class="btn small graphic_simple grey" title="Eliminar dirección" @click="eliminarDireccion(diretion.id)">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn small graphic grey" @click="nuevo">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                                    <span>Añadir dirección</span>
                                </div>
                                <div class="message info" v-if="user.addresses.length <= 0">
                                    <h3>Aún no has introducido ninguna direccion</h3>
                                </div>
                            </div>
                            <form class="form mt30" @submit.prevent="changeDirection" v-if="nuevoDirecion">
                                <Direccion :direccion="direccion"/>
                                <div class="input_block">
                                    <button type="submit" class="mt20">Cambiar datos</button>
                                </div>
                                <div class="input_block" v-if="error_address">
                                    <div class="message ko">
                                        <h3 v-html="error_address"></h3>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import Direccion from '@/components/Direccion.vue'

export default {
    name: 'Cuenta',
    components:{
        Direccion
    },
    data: function(){
        return {
            user: {
                addresses: [],
                orders: []
            },
            password_old: null,
            password_new: null,
            password_confirmed: null,
            direccion: {
                name: null,
                lastname: null,
                dni: null,
                phone: null,
                address: null,
                address2: null,
                cp: null,
                country: 0,
                province: 0,
                city: 0,
                dni: null
            },
            nuevoDirecion: false,
            error_address: null,
            message_password: null,
            message_password_type: false,
        }
    },
    methods: {
        get: function(){
            axios
                .get(`${process.env.VUE_APP_URL}my_user`, {
                    params:{
                        address: 1,
                        orders: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.user = rs.data;
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        nuevo: function(){
            this.nuevoDirecion = true;
            this.direccion = {
                name: null,
                lastname: null,
                dni: null,
                phone: null,
                address: null,
                address2: null,
                cp: null,
                country: 0,
                province: 0,
                city: 0,
                dni: null
            };
        },
        editar: function(id){
            this.nuevoDirecion = true;
            this.direccion = this.user.addresses[id];
        },
        changeDirection: function(){
            this.error_address = '';
            if(!this.direccion.title){
                this.error_address += 'Falta el titulo<br>';
            }
            if(!this.direccion.name){
                this.error_address += 'Falta el nombre<br>';
            }
            if(!this.direccion.lastname){
                this.error_address += 'Falta el apellido<br>';
            }
            if(!this.direccion.phone){
                this.error_address += 'Falta el telefono<br>';
            }
            if(!this.direccion.address){
                this.error_address += 'Falta el nombre de dirección<br>';
            }
            if(!this.direccion.cp){
                this.error_address += 'Falta el Codigo Postal (CP)<br>';
            }
            if(!this.direccion.country){
                this.error_address += 'Falta el Pais<br>';
            }
            if(!this.direccion.province){
                this.error_address += 'Falta la provincia<br>';
            }
            if(!this.direccion.city){
                this.error_address += 'Falta la ciudad<br>';
            }

            if(this.error_address){
                return;
            }

            this.error_address = null;
            let url = process.env.VUE_APP_URL+'addresses';
            if(this.direccion.id) url += `/${this.direccion.id}`;
            axios
                .post(url, {
                    user: this.$store.state.user,
                    title: this.direccion.title,
                    name: this.direccion.name,
                    lastname: this.direccion.lastname,
                    phone: this.direccion.phone,
                    address: this.direccion.address,
                    address2: this.direccion.address2,
                    cp: this.direccion.cp,
                    country: this.direccion.country.id,
                    province: this.direccion.province,
                    city: this.direccion.city,
                    dni: this.direccion.dni,
                    id: this.id
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.nuevoDirecion = false;
                        this.get();
                    }
                    else{
                        this.error_address = rs.msg;
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        },
        getTotalOrder: function(order){
            let total = 0;
            order.products.forEach(element => {
                total += element.quantity * element.price;
            });
            total += order.delivery_cost;
            return total;
        },
        verPedido: function(ref){
            this.$router.push(`/pedido/${ref}`);
        },
        eliminarDireccion: function(id){
            this.$parent.opcion = [
                '¿Deseas eleiminar?',
                () => {
                    axios
                        .post(process.env.VUE_APP_URL+`delete_addresses/${id}`)
                        .then(response => {
                            let rs = response.data;
                            if(rs.state == 'OK'){
                                this.$parent.error = null;
                                this.$parent.opcion = null;

                                this.get();
                            }
                            else{
                                this.$parent.opcion = null;
                                this.$parent.error = 'Fallo al eliminar';
                            }
                        })
                        .catch(error => {
                            this.$parent.opcion = null;
                            this.$parent.error = 'Fallo al eliminar';
                        })
                },
                () => {
                    this.$parent.error = null;
                    this.$parent.opcion = null;
                }
            ];
        },
        changePassword: function(){
            if(!this.password_old || !this.password_new){
                return;
            }
            if(this.password_new !== this.password_confirmed){
                this.message_password = "Las contraseñas no coinciden";
                this.message_password_type = false;
                this.clearPasswordMessage();
                return;
            }
            axios
            .post(`${process.env.VUE_APP_URL}change_password`,{
                password_l: this.password_old,
                password_n: this.password_new,
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK'){
                    this.message_password = "Contraseña cambiada con éxito";
                    this.message_password_type = true;
                }
                else{
                    this.message_password = "Hubo un erro al cambiar la contraseña";
                    this.message_password_type = false;
                }
            })
            .catch(error => {
                this.message_password = "Hubo un erro al cambiar la contraseña";
                this.message_password_type = false;
            })
            this.clearPasswordMessage();
        },
        clearPasswordMessage: function(){
            setTimeout(()=>{
                this.message_password = null;
                this.message_password_type = false;
            }, 3000);
        }
    },
    created: function(){
        if(!this.$store.state.user){
            window.oldUrl = '/checkout';
            this.$router.push('/login');
        }
        this.get();
    }
}
</script>